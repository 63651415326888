// Agents.js

import { FaCog, FaToggleOn, FaToggleOff, FaClone, FaTrash } from 'react-icons/fa';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DashboardHeader from './DashboardHeader';
import AgentForm from './toolbox/AgentForm'; // Import the AgentForm component
import { db } from '../firebase';
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
  addDoc,
  Timestamp,
} from 'firebase/firestore';
import { toast } from 'react-toastify'; // Importing react-toastify

// Styled Components for Agents.js
const AgentsContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
`;

const AgentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;

  /* Responsive Design: Adjust grid columns on smaller screens */
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 700;
`;

const CardStatus = styled.p`
  margin: 5px 0;
  font-size: 1rem;
`;

const CardIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  svg {
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: #ddd;
    }
  }
`;

const AgentCard = styled.div`
  background: #5a67d8;
  border-radius: 12px;
  padding: 20px;
  color: white;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  /* Responsive Design: Adjust padding on smaller screens */
  @media (max-width: 480px) {
    padding: 15px;
  }
`;

const AddAgentCard = styled.div`
  background: none;
  border: 2px dashed #cbd5e0;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #718096;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #edf2f7;
  }

  /* Responsive Design: Adjust padding on smaller screens */
  @media (max-width: 480px) {
    padding: 15px;
    font-size: 1rem;
  }
`;


// Schedule Schema with Default Values
const defaultSchedule = {
  monday: { start: '09:00', end: '18:00', is24Hours: true, closed: false, isScheduled: false },
  tuesday: { start: '09:00', end: '18:00', is24Hours: true, closed: false, isScheduled: false },
  wednesday: { start: '09:00', end: '18:00', is24Hours: true, closed: false, isScheduled: false },
  thursday: { start: '', end: '', is24Hours: false, closed: true, isScheduled: false },
  friday: { start: '09:00', end: '18:00', is24Hours: true, closed: false, isScheduled: false },
  saturday: { start: '', end: '', is24Hours: false, closed: true, isScheduled: false },
  sunday: { start: '', end: '', is24Hours: false, closed: true, isScheduled: false },
};

// Default Agent Data
const defaultAgentData = {
  active: true,
  agent_instruction:
    "You are Lia, a courteous customer service agent for a shop who will reply in taglish (60% english, 40% tagalog). You will not respond to anything beyond your scope as a customer service agent - meaning irrelevant topics outside your work.",
  ai_model: "default",
  creativity_level: 1,
  integrations: [], // This will be set from the form
  internal_feedback: "",
  knowledge_database: [],
  published: false,
  response_length: {
    max: 250,
  },
  store_information: {
    offerings: "We're currently on beta, about to sell you tons of amazing cookies!",
    // Add other keys with default values or empty strings if needed
  },
  // We'll set 'schedule' and 'name' separately
};

// Component Logic
const Agents = () => {
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null); // Track selected agent
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newAgentName, setNewAgentName] = useState('');
  const [newIntegrations, setNewIntegrations] = useState({ Messenger: false, Website: false });
  const [schedule, setSchedule] = useState(defaultSchedule);
  const [errors, setErrors] = useState({}); // To track validation errors

  // Fetch agents from Firestore
  const fetchAgents = async () => {
    try {
      const agentsCollection = collection(db, 'agents');
      const snapshot = await getDocs(agentsCollection);
      const agentsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAgents(agentsData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching agents:', error);
      toast.error('Failed to fetch agents. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  // Handle Add Agent Click
  const handleAddAgentClick = () => {
    setSelectedAgent(null); // Ensure no agent is selected for adding
    setNewAgentName('');
    setNewIntegrations({ Messenger: false, Website: false });
    setSchedule(defaultSchedule);
    setErrors({});
    setShowModal(true);
  };

  // Handle Edit Click
  const handleEditClick = (agent) => {
    setSelectedAgent(agent);
    setNewAgentName(agent.name);
  
    // Ensure integrations is always an array
    const integrationsArray = Array.isArray(agent.integrations) ? agent.integrations : [];
  
    setNewIntegrations({
      Messenger: integrationsArray.includes('Messenger'),
      Website: integrationsArray.includes('Website'),
    });
    setSchedule(agent.schedule || defaultSchedule);
    setErrors({});
    setShowModal(true);
  };
  

  // Toggle agent status in Firestore
  const toggleAgentStatus = async (agent) => {
    const updatedStatus = !agent.active;
    try {
      const agentRef = doc(db, 'agents', agent.id);
      await updateDoc(agentRef, { active: updatedStatus, last_updated: Timestamp.now() });
      setAgents(agents.map(a => (a.id === agent.id ? { ...a, active: updatedStatus } : a)));
      toast.success(`Agent set to ${updatedStatus ? 'Active' : 'Maintenance Mode'}.`);
    } catch (error) {
      console.error('Error updating agent status:', error);
      toast.error('Failed to update agent status. Please try again.');
    }
  };

  // Add new agent to Firestore
  const addAgent = async () => {
    const integrations = Object.keys(newIntegrations).filter(key => newIntegrations[key]);
    try {
      await addDoc(collection(db, 'agents'), {
        ...defaultAgentData,
        name: newAgentName,
        integrations,
        schedule,
        created_at: Timestamp.now(),
        last_updated: Timestamp.now(),
      });
      fetchAgents(); // Refetch agents after adding
      handleCloseModal();
      toast.success('Agent added successfully!');
    } catch (error) {
      console.error('Error adding agent:', error);
      toast.error('Failed to add agent. Please try again.');
    }
  };
  // Update agent in Firestore
  const updateAgent = async (agentId) => {
    const integrations = Object.keys(newIntegrations).filter(key => newIntegrations[key]);
    try {
      const agentRef = doc(db, 'agents', agentId);
      await updateDoc(agentRef, {
        name: newAgentName,
        integrations, // Now correctly storing as an array
        schedule,
        active: selectedAgent.active,
        last_updated: Timestamp.now(),
      });
      fetchAgents();
      handleCloseModal();
      toast.success('Agent updated successfully!');
    } catch (error) {
      console.error('Error updating agent:', error);
      toast.error('Failed to update agent. Please try again.');
    }
  };

  const handleDuplicateClick = (agent) => {
    setSelectedAgent(agent);
    setNewAgentName(''); // Or use `Copy of ${agent.name}`
    setNewIntegrations({
      Messenger: agent.integrations.includes('Messenger'),
      Website: agent.integrations.includes('Website'),
    });
    setSchedule(agent.schedule || defaultSchedule);
    setErrors({});
    setShowModal(true);
    setIsDuplicating(true);
  };

  const duplicateAgent = async () => {
    const integrations = Object.keys(newIntegrations).filter(key => newIntegrations[key]);
    try {
      const { id, name, created_at, last_updated, ...agentData } = selectedAgent;

      await addDoc(collection(db, 'agents'), {
        ...agentData,
        name: newAgentName,
        integrations,
        schedule,
        created_at: Timestamp.now(),
        last_updated: Timestamp.now(),
      });
      fetchAgents();
      handleCloseModal();
      toast.success('Agent duplicated successfully!');
    } catch (error) {
      console.error('Error duplicating agent:', error);
      toast.error('Failed to duplicate agent. Please try again.');
    }
  };


  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedAgent(null);
    setNewAgentName('');
    setNewIntegrations({ Messenger: false, Website: false });
    setSchedule(defaultSchedule);
    setErrors({});
    setIsDuplicating(false);
  };

  const handleDeleteClick = (agent) => {
    if (window.confirm(`Are you sure you want to delete the agent "${agent.name}"? This action cannot be undone.`)) {
      deleteAgent(agent.id);
    } else {
      toast.info('Agent deletion cancelled.');
    }
  };
  

  
  // Delete agent from Firestore
// Delete agent from Firestore
const deleteAgent = async (agentId) => {
  try {
    await deleteDoc(doc(db, 'agents', agentId));
    fetchAgents();
    handleCloseModal();
    toast.success('Agent deleted successfully!');
  } catch (error) {
    console.error('Error deleting agent:', error);
    toast.error('Failed to delete agent. Please try again.');
  }
};

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <DashboardHeader />

      <AgentsContainer>
        <Title>Manage Your Agents</Title>
        <AgentGrid>
          {agents.map(agent => (
            <AgentCard key={agent.id}>
              <CardHeader>
                <CardTitle>{agent.name}</CardTitle>
              </CardHeader>
              <CardStatus>
                Status: {agent.active ? 'Active' : 'Maintenance Mode'}
              </CardStatus>
              <CardIcons>
                <FaClone onClick={() => handleDuplicateClick(agent)} title="Duplicate Agent" />
                <FaCog onClick={() => handleEditClick(agent)} title="Edit Agent" />
                {agent.active ? (
                  <FaToggleOn
                    onClick={() => toggleAgentStatus(agent)}
                    title="Set to Maintenance Mode"
                  />
                ) : (
                  <FaToggleOff
                    onClick={() => toggleAgentStatus(agent)}
                    title="Set to Active"
                  />
                )}
                <FaTrash onClick={() => handleDeleteClick(agent)} title="Delete Agent" />
              </CardIcons>

            </AgentCard>
          ))}
          <AddAgentCard onClick={handleAddAgentClick} title="Add New Agent">
            + Add New Agent
          </AddAgentCard>
        </AgentGrid>

      {/* Modal for Adding/Editing/Duplicating Agent */}
      <AgentForm
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedAgent={selectedAgent}
        newAgentName={newAgentName}
        setNewAgentName={setNewAgentName}
        newIntegrations={newIntegrations}
        setNewIntegrations={setNewIntegrations}
        schedule={schedule}
        setSchedule={setSchedule}
        errors={errors}
        setErrors={setErrors}
        addAgent={addAgent}
        updateAgent={updateAgent}
        deleteAgent={deleteAgent}
        defaultSchedule={defaultSchedule}
        isDuplicating={isDuplicating} // New prop
        duplicateAgent={duplicateAgent} // New prop
        agents={agents} // Pass agents for name uniqueness validation
      />
      </AgentsContainer>
    </>
  );
};

export default Agents;
