import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom'; // Import NavLink for conditional styling

// Header Container
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(137,58,126);
  color: white;
  padding: 10px 20px;
  width: 100%;
  box-sizing: border-box;
`;

// Styled components for navigation and icons, aligned to the right
const NavLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
`;

const StyledNavLink = styled(NavLink)`
  color: white;
  text-decoration: none;
  font-weight: normal; // Default font weight

  &.active {
    font-weight: bold; // Bold font weight for active link
    color: white; // Change color to indicate active state
  }

  &:hover {
    color: #ccc;
  }
`;

const Icon = styled.span`
  background: white;
  color: #5f0a87;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;
`;

// Example header component
const DashboardHeader = () => {
  return (
    <HeaderContainer>
      <NavLinks>
        <StyledNavLink to="/dashboard" className="active">
          Dashboard
        </StyledNavLink>
        <StyledNavLink to="/training" className="active">
          Training
        </StyledNavLink>
        <StyledNavLink to="/agents" className="active">
          Agents
        </StyledNavLink>
        <StyledNavLink to="/settings" className="active">
          Settings
        </StyledNavLink>
      </NavLinks>
      <Icon>A</Icon>
    </HeaderContainer>
  );
};

export default DashboardHeader;
