import React from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import WordCloud from 'react-d3-cloud';
import { FaEnvelope, FaUsers, FaChartLine, FaCheckCircle } from 'react-icons/fa';
import DashboardHeader from './DashboardHeader';

// Register the necessary components in Chart.js
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

// Styles
const DashboardContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TopContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
`;

const Card = styled.div`
  background: linear-gradient(135deg, #ff6f61 0%, #de3adf 50%, #4a90e2 100%);
  border-radius: 12px;
  padding: 20px;
  color: white;
  display: flex;
  align-items: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }
`;

const IconWrapper = styled.div`
  font-size: 2rem;
  margin-right: 10px;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
`;

const CardValue = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
`;

const ChartContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  filter: blur(1px);
`;

const ChartHeader = styled.h3`  // Renamed to ChartHeader
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
`;

const WordCloudContainer = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 20px;
`;

const SampleText = styled.p`
  text-align: center;
  font-size: 14px;
  color: #666;
  margin-top: 10px;
`;

// Dummy data for the charts
const lineData = {
  labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      label: 'Messages Sent',
      data: [12, 19, 3, 5, 2, 3, 10],
      fill: false,
      backgroundColor: '#007bff',
      borderColor: '#007bff',
      tension: 0.4,
    },
  ],
};

const wordData = [
  { text: 'React', value: 1000 },
  { text: 'Chart', value: 800 },
  { text: 'D3', value: 600 },
  { text: 'JavaScript', value: 500 },
  { text: 'WordCloud', value: 400 },
  { text: 'Design', value: 300 },
  { text: 'Components', value: 200 },
  { text: 'Styled', value: 100 },
];

const fontSizeMapper = word => Math.log2(word.value) * 5;

const Dashboard = () => (
  <>
  <DashboardHeader/>
  
  <DashboardContainer>
    <Title>Dashboard</Title>
    <TopContainer>
      <Card>
        <IconWrapper><FaEnvelope /></IconWrapper>
        <CardContent>
          <CardTitle>Total Messages</CardTitle>
          <CardValue>1500</CardValue>
        </CardContent>
      </Card>
      <Card>
        <IconWrapper><FaUsers /></IconWrapper>
        <CardContent>
          <CardTitle>Total Users</CardTitle>
          <CardValue>120</CardValue>
        </CardContent>
      </Card>
      <Card>
        <IconWrapper><FaChartLine /></IconWrapper>
        <CardContent>
          <CardTitle>Active Sessions</CardTitle>
          <CardValue>15</CardValue>
        </CardContent>
      </Card>
      <Card>
        <IconWrapper><FaCheckCircle /></IconWrapper>
        <CardContent>
          <CardTitle>System Status</CardTitle>
          <CardValue>Good</CardValue>
        </CardContent>
      </Card>
    </TopContainer>

    <ChartContainer>
      <ChartHeader>Messages Sent per Day</ChartHeader>
      <Line data={lineData} />
      <SampleText>Sample Data: Messages Sent Trend</SampleText>
    </ChartContainer>

    <ChartContainer>
      <ChartHeader>Word Cloud</ChartHeader>
      <WordCloudContainer>
        <WordCloud data={wordData} fontSizeMapper={fontSizeMapper} rotate={0} />
      </WordCloudContainer>
      <SampleText>Sample Data: Word Bank Cloud</SampleText>
    </ChartContainer>
  </DashboardContainer>
  </>
);

export default Dashboard;
