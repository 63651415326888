// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import styled from 'styled-components';
import { FaLock } from 'react-icons/fa';

// Styled Components for Unauthorized Access
const RestrictedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f4f8;
  text-align: center;
  padding: 0px 20px;
`;

const LockIcon = styled(FaLock)`
  font-size: 50px;
  color: #e74c3c;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
`;

const Message = styled.p`
  font-size: 18px;
  color: #555;
  margin-bottom: 30px;
`;

const ContactLink = styled.a`
  color: #007bff;
  font-size: 16px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

// Unauthorized Component
const UnauthorizedAccess = () => (
  <RestrictedContainer>
    <LockIcon />
    <Title>Unauthorized Access</Title>
    <Message>
    Sorry if you can't access this page at the moment. <br/> <br/> For approvals to access, please contact{' '}
      <ContactLink href="mailto:likha@helloandy.ai">likha@helloandy.ai</ContactLink> for assistance.
    </Message>
  </RestrictedContainer>
);

// PrivateRoute Component
const PrivateRoute = ({ children, requiredRoles = [] }) => {
  const { currentUser, userRoles } = useAuth();

  // **1. Authentication Check**
  if (!currentUser) {
    // If the user is not authenticated, redirect to the login page
    return <Navigate to="/login" replace />;
  }

  // **2. Authorization Check**
  if (requiredRoles.length > 0) {
    // Check if the user has at least one of the required roles
    const hasRequiredRole = userRoles.some((role) => requiredRoles.includes(role.id));

    if (!hasRequiredRole) {
      // If the user lacks the required role, redirect to the unauthorized page
      return <UnauthorizedAccess />;
    }
  }

  // **3. Access Granted**
  // If the user is authenticated and has the required role(s), render the protected component
  return children;
};

export default PrivateRoute;
