// AgentForm.js

import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

// Styled Components for AgentForm.js
const ModalOverlay = styled.div`
  display: ${({ $show }) => ($show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  /* Responsive Design: Adjust alignment on smaller screens */
  @media (max-width: 480px) {
    align-items: flex-start;
    padding-top: 20px;
  }
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  max-width: 700px; /* Increased width to accommodate schedule settings */
  width: 90%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  position: relative;
  max-height: 90vh;
  overflow-y: auto;

  /* Responsive Design: Adjust width on smaller screens */
  @media (max-width: 768px) {
    max-width: 600px;
  }

  @media (max-width: 480px) {
    max-width: 95%;
    padding: 20px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    color: #e53e3e;
  }
`;

const ModalTitle = styled.h3`
  margin-bottom: 20px;
  text-align: center;
`;

const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Label = styled.label`
  font-size: 1rem;
  font-weight: 600;
`;

const Input = styled.input`
  padding: 10px;
  border: 1px solid ${({ $error }) => ($error ? '#e53e3e' : '#ccc')};
  border-radius: 8px;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #5a67d8;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
`;

const DeleteButton = styled.button`
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #e53e3e;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #c53030;
  }
`;

const SaveButton = styled.button`
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #38a169; /* Changed to a success color */
  color: white;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2f855a;
  }

  &:disabled {
    background-color: #a0aec0;
    cursor: not-allowed;
  }
`;

// Styled Component for Error Messages
const ErrorText = styled.p`
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 5px;
`;

// Styled Component for Reset Button
const ResetButton = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 8px;
  background-color: #cbd5e0;
  color: #2d3748;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a0aec0;
  }
`;

const AgentForm = ({
  showModal,
  handleCloseModal,
  selectedAgent,
  newAgentName,
  setNewAgentName,
  newIntegrations,
  setNewIntegrations,
  schedule,
  setSchedule,
  errors,
  setErrors,
  addAgent,
  updateAgent,
  deleteAgent,
  defaultSchedule,
  isDuplicating, // New prop
  duplicateAgent, // New prop
  agents, // New prop
}) => {
  // ... [Include your helper functions and form validation here as in your original code]

  // Handle Time Change
  const handleTimeChange = (day, field, value) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: { ...prev[day], [field]: value },
    }));
  };

  // Handle Option Toggle
  const handleOptionToggle = (day, option) => {
    setSchedule((prev) => ({
      ...prev,
      [day]: {
        is24Hours: option === 'is24Hours',
        isScheduled: option === 'isScheduled',
        closed: option === 'closed',
        start: '',
        end: '',
      },
    }));
  };

  // Handle Schedule Reset
  const handleResetSchedule = () => {
    setSchedule(defaultSchedule);
    setErrors({});
  };

  // Form Validation
  const validateForm = () => {
    const newErrors = {};
    const errorMessages = [];

    // Validate Agent Name
    if (!newAgentName.trim()) {
      const errorMessage = 'Agent name is required.';
      newErrors.name = errorMessage;
      errorMessages.push(errorMessage);
    } else {
      // Check if agent name is unique (case-insensitive)
      const isNameTaken = agents.some(
        (agent) =>
          agent.name.toLowerCase() === newAgentName.trim().toLowerCase() &&
          agent.id !== (selectedAgent ? selectedAgent.id : '')
      );
      if (isNameTaken) {
        const errorMessage = 'An agent with this name already exists.';
        newErrors.name = errorMessage;
        errorMessages.push(errorMessage);
      }
    }

    // Validate Schedule
    Object.keys(schedule).forEach((day) => {
      const daySchedule = schedule[day];
      const dayName = capitalizeFirstLetter(day);

      // Ensure at least one option is selected
      if (!daySchedule.is24Hours && !daySchedule.isScheduled && !daySchedule.closed) {
        const errorMessage = `Please select an option for ${dayName}.`;
        newErrors[`${day}_options`] = errorMessage;
        errorMessages.push(errorMessage);
      }

      // If Scheduled, ensure start and end times are provided
      if (daySchedule.isScheduled) {
        if (!daySchedule.start) {
          const errorMessage = `${dayName} start time is required when Scheduled.`;
          newErrors[`${day}_start`] = errorMessage;
          errorMessages.push(errorMessage);
        }
        if (!daySchedule.end) {
          const errorMessage = `${dayName} end time is required when Scheduled.`;
          newErrors[`${day}_end`] = errorMessage;
          errorMessages.push(errorMessage);
        }
        // Ensure start time is before end time
        if (daySchedule.start && daySchedule.end && daySchedule.start >= daySchedule.end) {
          const errorMessage = `${dayName} start time must be before end time.`;
          newErrors[`${day}_time`] = errorMessage;
          errorMessages.push(errorMessage);
        }
      }
    });

    setErrors(newErrors);
    return errorMessages;
  };

  // Capitalize first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <ModalOverlay $show={showModal}>
      <ModalContent>
        <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
        <ModalTitle>
          {isDuplicating
            ? `Duplicate Agent: ${selectedAgent.name}`
            : selectedAgent
            ? `Edit Agent: ${selectedAgent.name}`
            : 'Add New Agent'}
        </ModalTitle>
        <ModalForm
          onSubmit={(e) => {
            e.preventDefault();
            const validationErrors = validateForm();
            if (validationErrors.length === 0) {
              if (isDuplicating) {
                duplicateAgent();
              } else if (selectedAgent) {
                updateAgent(selectedAgent.id);
              } else {
                addAgent();
              }
            } else {
              // Display all validation errors in a single toast
              toast.error(
                <div>
                  Please fix the following errors:
                  <ul>
                    {validationErrors.map((msg, index) => (
                      <li key={index}>{msg}</li>
                    ))}
                  </ul>
                </div>,
                { autoClose: 10000 }
              );
            }
          }}
        >
          {/* Agent Name */}
          <div>
            <Label>Agent Name</Label>
            <Input
              type="text"
              value={newAgentName}
              onChange={(e) => setNewAgentName(e.target.value)}
              required
              placeholder="Enter agent name"
              $error={errors.name}
            />
            {errors.name && <ErrorText>{errors.name}</ErrorText>}
          </div>

          {/* Integrations */}
          <div>
            <Label>Integrations</Label>
            <CheckboxGroup>
              <label>
                <input
                  type="checkbox"
                  checked={newIntegrations.Messenger}
                  onChange={() =>
                    setNewIntegrations((prev) => ({
                      ...prev,
                      Messenger: !prev.Messenger,
                    }))
                  }
                />{' '}
                Messenger
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={newIntegrations.Website}
                  onChange={() =>
                    setNewIntegrations((prev) => ({
                      ...prev,
                      Website: !prev.Website,
                    }))
                  }
                />{' '}
                Website
              </label>
              {/* Add more integrations as needed */}
            </CheckboxGroup>
          </div>

          {/* Schedule Settings */}
          <div>
            <Label>Schedule</Label>
            {Object.keys(schedule).map((day) => (
              <div key={day} style={{ marginBottom: '15px' }}>
                <h4 style={{ margin: '10px 0 5px 0', textTransform: 'capitalize' }}>
                  {day}
                </h4>
                <CheckboxGroup>
                  <label>
                    <input
                      type="radio"
                      name={`schedule_option_${day}`}
                      checked={schedule[day].is24Hours}
                      onChange={() => handleOptionToggle(day, 'is24Hours')}
                    />{' '}
                    24 Hours
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`schedule_option_${day}`}
                      checked={schedule[day].isScheduled}
                      onChange={() => handleOptionToggle(day, 'isScheduled')}
                    />{' '}
                    Scheduled
                  </label>
                  <label>
                    <input
                      type="radio"
                      name={`schedule_option_${day}`}
                      checked={schedule[day].closed}
                      onChange={() => handleOptionToggle(day, 'closed')}
                    />{' '}
                    Closed
                  </label>
                </CheckboxGroup>
                {/* Display validation error for option selection */}
                {errors[`${day}_options`] && <ErrorText>{errors[`${day}_options`]}</ErrorText>}
                {/* Show time inputs only if Scheduled is selected */}
                {schedule[day].isScheduled && (
                  <>
                    <div style={{ marginTop: '10px' }}>
                      <Label>Start Time</Label>
                      <Input
                        type="time"
                        value={schedule[day].start}
                        onChange={(e) => handleTimeChange(day, 'start', e.target.value)}
                        required={schedule[day].isScheduled}
                        $error={errors[`${day}_start`] || errors[`${day}_time`]}
                      />
                      {errors[`${day}_start`] && <ErrorText>{errors[`${day}_start`]}</ErrorText>}
                    </div>
                    <div style={{ marginTop: '10px' }}>
                      <Label>End Time</Label>
                      <Input
                        type="time"
                        value={schedule[day].end}
                        onChange={(e) => handleTimeChange(day, 'end', e.target.value)}
                        required={schedule[day].isScheduled}
                        $error={errors[`${day}_end`] || errors[`${day}_time`]}
                      />
                      {errors[`${day}_end`] && <ErrorText>{errors[`${day}_end`]}</ErrorText>}
                    </div>
                    {/* Display validation error for time order */}
                    {errors[`${day}_time`] && <ErrorText>{errors[`${day}_time`]}</ErrorText>}
                  </>
                )}
              </div>
            ))}
            <ResetButton type="button" onClick={handleResetSchedule}>
              Reset Schedule to Default
            </ResetButton>
          </div>

          {/* Save and Delete Buttons */}
          <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
            <SaveButton type="submit">
              {isDuplicating ? 'Duplicate Agent' : selectedAgent ? 'Save Changes' : 'Add Agent'}
            </SaveButton>
            {selectedAgent && !isDuplicating && (
              <DeleteButton
  type="button"
  onClick={() => {
    if (window.confirm('Are you sure you want to delete this agent? This action cannot be undone.')) {
      deleteAgent(selectedAgent.id);
    } else {
      toast.info('Agent deletion cancelled.');
    }
  }}
>
  Delete Agent
</DeleteButton>
            )}
          </div>
        </ModalForm>
      </ModalContent>
    </ModalOverlay>
  );
};

export default AgentForm;