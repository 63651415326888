// /src/components/AboutUs.js
import React from 'react';
import styled from 'styled-components';
import PublicLayout from './PublicLayout';

const Container = styled.div`
  padding: 60px 15%;
  background-color: #fff;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  min-height: calc(100vh - 200px);

  @media (max-width: 768px) {
    padding: 40px 10%;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #6a11cb;
  margin-bottom: 40px;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 60px;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  margin-bottom: 20px;
`;

const ValuesList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ValueItem = styled.li`
  font-size: 1rem;
  margin-bottom: 10px;
  &::before {
    content: '•';
    color: #6a11cb;
    margin-right: 10px;
  }
`;

const AboutUs = () => {
  return (
    <PublicLayout>
      <Container>
        <Title>About AndyAI</Title>

        <Section>
          <SectionTitle>Our Story</SectionTitle>
          <Paragraph>
            AndyAI was born out of a desire to transform the way businesses operate. Our team, once working tirelessly for 12-hour days on repetitive tasks in customer service and graphic design, envisioned a future where time is spent on meaningful, impactful work. We believed that by harnessing the power of artificial intelligence, we could eliminate mundane tasks and empower teams to focus on innovation and growth.
          </Paragraph>
          <Paragraph>
            With over 8 years of experience in machine learning and a passion for simplifying complex technologies, we set out to make AI accessible to businesses of all sizes. Today, AndyAI is dedicated to helping enterprises maximize productivity through intelligent AI agents that streamline operations and drive efficiency.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Our Mission</SectionTitle>
          <Paragraph>
            To make it easy for any business to adopt AI, transforming repetitive tasks into opportunities for innovation.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Our Vision</SectionTitle>
          <Paragraph>
            <strong>By 2030:</strong> Reduce 10 million hours of repetitive work through AI.
          </Paragraph>
          <Paragraph>
            <strong>By 2035:</strong> Enable 1 million businesses to integrate artificial intelligence seamlessly into their operations.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Our Values</SectionTitle>
          <ValuesList>
            <ValueItem>Adaptive</ValueItem>
            <ValueItem>Integrity</ValueItem>
            <ValueItem>Accountability</ValueItem>
            <ValueItem>Grit</ValueItem>
            <ValueItem>Data-Driven</ValueItem>
            <ValueItem>Empathy</ValueItem>
          </ValuesList>
        </Section>

        <Section>
          <SectionTitle>What We Do</SectionTitle>
          <Paragraph>
            AndyAI offers a user-friendly AI platform that simplifies the integration of artificial intelligence into business operations. Our flagship product empowers enterprises to automate workflows, make data-driven decisions, and transform productivity. By reducing the burden of repetitive tasks, we enable teams to focus on what truly matters—driving innovation and growth.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Our Unique Approach</SectionTitle>
          <Paragraph>
            At AndyAI, we simplify processes by minimizing human interaction through intelligent AI agents. Unlike traditional models that require extensive training and user input, our technology utilizes one AI to perform tasks and another to audit them. This innovative approach streamlines operations and reduces human error, setting us apart in the industry.
          </Paragraph>
          <Paragraph>
            Backed by a diverse team and proprietary research, we integrate the best practices from leading AI developments globally. Our engineering-led culture allows us to innovate rapidly, maintaining full control over our products and accelerating the research-to-application process.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Our Team</SectionTitle>
          <Paragraph>
            Our team comprises passionate engineers, innovators, and industry experts dedicated to revolutionizing the way businesses use AI. With a strong network of tech founders and connections worldwide, including organizations like Alibaba, we are strategically positioned to lead in AI innovation.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Join Us on This Journey</SectionTitle>
          <Paragraph>
            We invite you to be part of our mission to reshape the future of work. Whether you're a business looking to enhance efficiency or a professional eager to leverage AI, AndyAI is here to simplify the journey. Together, we can unlock new possibilities and drive meaningful change.
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>Contact Us</SectionTitle>
          <Paragraph>
            Interested in learning more about how AndyAI can transform your business? Reach out to us at <a href="mailto:andy@helloandyai.com">andy@helloandyai.com</a> or visit our website for more information.
          </Paragraph>
        </Section>
      </Container>
    </PublicLayout>
  );
};

export default AboutUs;
