// NavigationBar.js
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import SettingsIcon from '@mui/icons-material/Settings';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AndyAiIcon from '../assets/AndyAI-IconWhite.png'; // Adjust the path as necessary
import { useAuth } from '../contexts/AuthContext';

// Styled Components with Transient Props (prefixed with $)
const Nav = styled.nav`
  background: rgb(137,58,126);
  color: white;
  width: ${({ $collapsed }) => ($collapsed ? '60px' : '180px')};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 10px 0px 10px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease;
`;

const BrandLink = styled(NavLink)`
  display: block;
  margin: 0 auto 30px auto;
  width: 100%;
  max-width: ${({ $collapsed }) => ($collapsed ? '40px' : '80px')};
  transition: max-width 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const BrandIcon = styled.img`
  width: 100%;
  height: auto;
  display: block;
  margin-top: 20px;
`;

const NavLinks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: white;
  padding: 12px 18px;
  text-decoration: none;
  border-radius: 12px;
  font-size: 16px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &.active {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    transform: scale(1.05);
  }

  svg {
    margin-right: ${({ $collapsed }) => ($collapsed ? '0' : '12px')};
    transition: margin-right 0.3s ease, transform 0.3s ease;
    font-size: 20px;
  }
`;

const ToggleButton = styled.button`
  background: transparent;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  transition: color 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  &:hover {
    color: #ddd;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  svg {
    transition: transform 0.3s ease;
  }
`;

const LogoutButton = styled.button`
  background: transparent;
  color: white;
  border: none;
  padding: 12px 18px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  font-size: 16px;
  border-radius: 12px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    color: #f0f0f0;
    transform: scale(1.05);
  }

  svg {
    margin-right: 12px;
    transition: margin-right 0.3s ease;
    font-size: 20px;
  }
`;

const NavigationBar = () => {
  const { logout } = useAuth();

  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout(); // clear authentication
    navigate('/login');
  };

  return (
    <Nav $collapsed={collapsed}>
      <div>
        <BrandLink to="/" $collapsed={collapsed}>
          <BrandIcon src={AndyAiIcon} alt="AndyAI Logo" />
        </BrandLink>

        <NavLinks>
          <StyledNavLink to="/dashboard" $collapsed={collapsed}>
            <DashboardIcon />
            {!collapsed && 'Dashboard'}
          </StyledNavLink>
          <StyledNavLink to="/agents" $collapsed={collapsed}>
            <PeopleIcon />
            {!collapsed && 'Agents'}
          </StyledNavLink>
          <StyledNavLink to="/training" $collapsed={collapsed}>
            <FitnessCenterIcon />
            {!collapsed && 'Training'}
          </StyledNavLink>
          <StyledNavLink to="/settings" $collapsed={collapsed}>
            <SettingsIcon />
            {!collapsed && 'Settings'}
          </StyledNavLink>
          <StyledNavLink to="/user-access" $collapsed={collapsed}>
            <SupervisedUserCircleIcon />
            {!collapsed && 'UserAccess'}
          </StyledNavLink>
        </NavLinks>
      </div>

      <div>
        <LogoutButton onClick={handleLogout}>
          <ExitToAppIcon />
          {!collapsed && 'Logout'}
        </LogoutButton>
        <ToggleButton onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </ToggleButton>
      </div>
    </Nav>
  );
};

export default NavigationBar;
