// src/components/UserAccess.styled.js
import styled from 'styled-components';
import { FaUserPlus } from 'react-icons/fa';

// Container Styles
export const Container = styled.div`
  padding: 30px 20px;
  max-width: 1400px;
  margin: 0 auto;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgbaSty(0, 0, 0, 0.1);
  border-radius: 8px;
`;

// Header Styles
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

export const Title = styled.h2`
  font-size: 24px;
  color: #333333;
  margin-bottom: 10px;
`;

export const SearchExportContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

// Search Input Styles
export const SearchInput = styled.input`
  padding: 8px 12px;
  width: 250px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 14px;

  &:focus {
    outline: none;
    border-color: #6a11cb;
    box-shadow: 0 0 0 2px rgba(106, 17, 203, 0.2);
  }
`;

// Export Button Styles
export const ExportButton = styled.button`
  padding: 8px 12px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: #1e8449;
  }

  svg {
    margin-right: 5px;
  }
`;

// Add User Button Styles
export const AddUserButton = styled.button`
  padding: 8px 12px;
  background-color: #2980b9;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;

  &:hover {
    background-color: #1c5980;
  }

  svg {
    margin-right: 5px;
  }
`;

// Add User Form Styles
export const AddUserForm = styled.form`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 5px;
    font-weight: bold;
  }

  input {
    padding: 8px 10px;
    margin-bottom: 15px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 14px;
  }

  button {
    align-self: flex-end;
  }
`;

// Column Toggle Styles
export const ColumnToggleContainer = styled.div`
  position: relative;
`;

export const ColumnToggleButton = styled.button`
  padding: 8px 12px;
  background-color: #6a11cb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #5a0da7;
  }

  svg {
    margin-right: 5px;
  }
`;

export const ColumnOptions = styled.div`
  position: absolute;
  top: 45px;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 10px;
  z-index: 100;
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
`;

export const ColumnOption = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  input {
    margin-right: 8px;
  }

  label {
    font-size: 14px;
    color: #333333;
  }
`;

// Table Styles
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const Th = styled.th`
  text-align: left;
  padding: 12px;
  background-color: #f8f9fa;
  border-bottom: 2px solid #dddddd;
  font-size: 14px;
  color: #555555;
  position: relative;
  cursor: pointer;

  &:nth-child(1) {
    width: 80px; /* For UID */
  }

  &:nth-child(2) {
    width: 200px; /* For Email */
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Td = styled.td`
  padding: 10px 12px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 13px;
  color: #555555;
  word-wrap: break-word;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

// Action Button Styles
export const ActionButton = styled.button`
  padding: 6px 10px;
  margin-right: 6px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
  font-size: 12px;

  &:hover {
    opacity: 0.9;
  }

  svg {
    margin-right: 4px;
  }

  background-color: ${({ variant }) =>
    variant === 'view' ? '#3498db' :
    variant === 'edit' ? '#2ecc71' :
    variant === 'delete' ? '#e74c3c' :
    '#95a5a6'};
`;

// Modal Styles
export const ModalContent = styled.div`
  padding: 20px;
`;

export const ModalHeader = styled.h3`
  margin-bottom: 20px;
  color: #333333;
`;

export const CloseButton = styled.button`
  padding: 8px 12px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }
`;

export const SaveButton = styled.button`
  padding: 8px 12px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }
`;

// Permission List Styles
export const PermissionList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const PermissionItem = styled.li`
  padding: 8px 0;
  border-bottom: 1px solid #eeeeee;
`;

// Checkbox Label Styles
export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;

  input {
    margin-right: 8px;
  }
`;

// Pagination Styles
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const PageButton = styled.button`
  padding: 6px 10px;
  margin: 4px;
  background-color: ${({ active }) => (active ? '#6a11cb' : '#f2f2f2')};
  color: ${({ active }) => (active ? 'white' : '#333333')};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    background-color: ${({ active }) => (active ? '#5a0da7' : '#dddddd')};
  }
`;

// Sort Icon Styles
export const SortIcon = styled.span`
  margin-left: 5px;
  display: flex;
  align-items: center;
`;
