// src/utils/userService.js
import { collection, setDoc, addDoc, getDocs, getDoc, doc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

const usersCollection = collection(db, 'users');
const rolesCollection = collection(db, 'roles');

/**
 * Adds a new user to the Firestore 'users' collection.
 * 
 * @param {string} uid - The UID of the user from Firebase Auth.
 * @param {string} email - The email of the user.
 */

export const addUserToFirestore = async (uid, email) => {
  if (!uid || !email) {
    throw new Error('UID and Email are required to add a user.');
  }

  const userRef = doc(db, 'users', uid);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    throw new Error('User already exists in Firestore.');
  }

  const userData = {
    uid,
    email,
    roles: ['unverified'],
    create: serverTimestamp(),
    last_login: serverTimestamp(),
    status: 'unverified',
    first_name: '',
    last_name: '',
    profile_image_url: '',
    phone_number: '',
    metadata: {},
  };

  try {
    await setDoc(userRef, userData);
    return { success: true };
  } catch (error) {
    console.error('Error adding user to Firestore:', error);
    return { success: false, error };
  }
};

/**
 * Fetches all roles from the Firestore 'roles' collection.
 */
export const getRoles = async () => {
  try {
    const snapshot = await getDocs(rolesCollection);
    const roles = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return { success: true, roles };
  } catch (error) {
    console.error('Error fetching roles:', error);
    return { success: false, error };
  }
};



// Create a new user
export const createUser = async (userData) => {
  try {
    const docRef = await addDoc(usersCollection, userData);
    return { success: true, id: docRef.id };
  } catch (error) {
    console.error('Error adding document: ', error);
    return { success: false, error };
  }
};

export const updateLastLogin = async (uid) => {
  try {
    const userRef = doc(db, 'users', uid);
    await updateDoc(userRef, {
      last_login: serverTimestamp(),
    });
    console.log(`Last login updated for UID: ${uid}`);
  } catch (error) {
    console.error(`Error updating last login for UID: ${uid}`, error);
  }
};


// Read all users
export const getUsers = async () => {
  try {
    const snapshot = await getDocs(usersCollection);
    const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    return { success: true, users };
  } catch (error) {
    console.error('Error fetching documents: ', error);
    return { success: false, error };
  }
};

// Read a single user by ID
export const getUserById = async (id) => {
  try {
    const userDoc = doc(db, 'users', id);
    const snapshot = await getDoc(userDoc);
    if (snapshot.exists()) {
      return { success: true, user: { id: snapshot.id, ...snapshot.data() } };
    } else {
      return { success: false, error: 'No such document!' };
    }
  } catch (error) {
    console.error('Error fetching document: ', error);
    return { success: false, error };
  }
};

// Update a user by ID
export const updateUser = async (id, updatedData) => {
  try {
    const userDoc = doc(db, 'users', id);
    await updateDoc(userDoc, updatedData);
    return { success: true };
  } catch (error) {
    console.error('Error updating document: ', error);
    return { success: false, error };
  }
};

// Delete a user by ID
export const deleteUser = async (id) => {
  try {
    const userDoc = doc(db, 'users', id);
    await deleteDoc(userDoc);
    return { success: true };
  } catch (error) {
    console.error('Error deleting document: ', error);
    return { success: false, error };
  }
};
