import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';

// Global style to prevent unwanted scroll bars
const GlobalStyles = styled.div`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
  }

  body {
    font-family: Arial, sans-serif;
    scroll-behavior: smooth;
  }
`;

const PageWrapper = styled.div`
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex: 1;
  /* You can add padding or styling specific to the content area */
`;

const PublicLayout = ({ children }) => {
  return (
    <PageWrapper>
      <GlobalStyles />
      <Header />
      <ContentWrapper>
        {children}
      </ContentWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default PublicLayout;
