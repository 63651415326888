// /src/components/Newsletter.js
import React, { useState } from 'react';
import styled from 'styled-components';
import PublicLayout from './PublicLayout';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const Container = styled.div`
  padding: 60px 15%;
  background-color: #fff;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  line-height: 1.6;
  min-height: calc(100vh - 200px);

  @media (max-width: 768px) {
    padding: 40px 10%;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #6a11cb;
  margin-bottom: 20px;
  text-align: center;
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 40px;
`;

const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
`;

const Input = styled.input`
  padding: 14px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 70%;
  box-sizing: border-box;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Button = styled.button`
  padding: 14px;
  font-size: 1.2rem;
  background-color: #6a11cb;
  color: white;
  border: none;
  border-radius: 8px;
  width: 30%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;

  &:hover {
    background-color: #5a0da7;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const StatusMessage = styled('p').withConfig({
    shouldForwardProp: (prop) => prop !== 'success',
  })`
    margin-top: 20px;
    color: ${({ success }) => (success ? 'green' : 'red')};
    font-weight: bold;
    text-align: center;
  `;
  
  const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [statusMessage, setStatusMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    // Email validation function
    const validateEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
  
    // Function to handle form submission
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!validateEmail(email)) {
        setStatusMessage('Please enter a valid email.');
        setSuccess(false);
        return;
      }
  
      if (isSubmitting) {
        return; // Prevent multiple submissions
      }
  
      setIsSubmitting(true);
  
      try {
        // Add email to Firestore collection
        await addDoc(collection(db, 'newsletter'), { email });
        setStatusMessage('Thank you for subscribing to our newsletter!');
        setSuccess(true);
        setEmail(''); // Clear the email input
      } catch (error) {
        if (error.code === 'permission-denied') {
          setStatusMessage('Permission denied. Please contact support.');
        } else {
          setStatusMessage('An error occurred. Please try again.');
        }
        setSuccess(false);
        console.error('Error adding document: ', error);
      }
      
  
      setTimeout(() => {
        setIsSubmitting(false);
      }, 1000); // Allow submission after 1 second
    };
  
    return (
      <PublicLayout>
        <Container>
          <Title>Join Our AI Insights Newsletter</Title>
          <Subtitle>
            Stay ahead with the latest AI news, tips on integrating AI into your business, and access to free AI tools and resources to boost your productivity.
          </Subtitle>
          <Form onSubmit={handleSubmit}>
            <Input
              type="email"
              placeholder="Enter your email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Subscribe'}
            </Button>
          </Form>
          {statusMessage && (
            <StatusMessage success={success}>{statusMessage}</StatusMessage>
          )}
        </Container>
      </PublicLayout>
    );
  };
  
  export default Newsletter;