// src/components/UserAccess.js
import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  Title,
  SearchExportContainer,
  SearchInput,
  ExportButton,
  AddUserButton,
  AddUserForm,
  ColumnToggleContainer,
  ColumnToggleButton,
  ColumnOptions,
  ColumnOption,
  Table,
  Th,
  HeaderContent,
  Td,
  ActionButton,
  ModalContent,
  ModalHeader,
  CloseButton,
  SaveButton,
  CheckboxLabel,
  PaginationContainer,
  PageButton,
  SortIcon
} from './UserAccess.styled';
import { getUsers, updateUser, deleteUser, addUserToFirestore, getRoles } from '../utils/userService';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { 
  FaEye, 
  FaEdit, 
  FaTrash, 
  FaCog, 
  FaSort, 
  FaSortUp, 
  FaSortDown, 
  FaFileExport,
  FaUserPlus
} from 'react-icons/fa';
import DashboardHeader from './DashboardHeader';
import * as XLSX from 'xlsx'; // Ensure this library is installed: npm install xlsx

const UserAccess = () => {
  const { currentUser, userRoles } = useAuth(); // Destructure userRoles from context
  const [users, setUsers] = useState([]); // All users
  const [filteredUsers, setFilteredUsers] = useState([]); // Users after search and sort
  const [searchTerm, setSearchTerm] = useState('');
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false); // New state for Add User Modal
  const [selectedUser, setSelectedUser] = useState(null);
  const [editableData, setEditableData] = useState({});
  const [visibleColumns, setVisibleColumns] = useState({
    uid: false,
    email: true,
    first_name: true,
    last_name: false,
    status: false,
    phone_number: false,
    roles: true, // New column for Roles
    metadata: false, // Nested data, optional to display
  });
  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);

  // New States for Add User Form
  const [newUserUID, setNewUserUID] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10; // Adjust as needed

  // Sorting States
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // State to hold available roles from Firestore
  const [availableRoles, setAvailableRoles] = useState([]);

  // Define all possible columns
  const allColumns = {
    uid: 'UID',
    email: 'Email',
    first_name: 'First Name',
    last_name: 'Last Name',
    status: 'Status',
    phone_number: 'Phone Number',
    roles: 'Roles', // New column for Roles
    metadata: 'Metadata',
  };

  // Determine if current user is admin
  const isAdmin = userRoles.some(role => role.id === 'admin'); // Adjust based on role object structure
  console.log("🚀 ~ UserAccess ~ isAdmin:", isAdmin);
  console.log("🚀 ~ UserAccess ~ userRoles:", userRoles);
  console.log("currentUser?", currentUser);

  // Fetch all users (could be optimized if needed)
  useEffect(() => {
    const fetchUsersData = async () => {
      if (!currentUser) {
        console.log('User is not authenticated.');
        return;
      }

      const response = await getUsers();
      if (response.success) {
        setUsers(response.users);
        setFilteredUsers(response.users);
      } else {
        console.error(response.error);
        toast.error('Error fetching users.');
      }
    };

    fetchUsersData();
  }, [currentUser]);

  // Fetch available roles from Firestore
  useEffect(() => {
    const fetchRoles = async () => {
      const response = await getRoles();
      if (response.success) {
        setAvailableRoles(response.roles);
      } else {
        console.error(response.error);
        toast.error('Error fetching roles.');
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    // Filter users based on search term across multiple fields
    let updatedUsers = [...users];

    if (searchTerm.trim() !== '') {
      const lowercasedTerm = searchTerm.toLowerCase();
      updatedUsers = updatedUsers.filter(user =>
        (user.first_name && user.first_name.toLowerCase().includes(lowercasedTerm)) ||
        (user.last_name && user.last_name.toLowerCase().includes(lowercasedTerm)) ||
        (user.email && user.email.toLowerCase().includes(lowercasedTerm)) ||
        (user.roles && user.roles.join(', ').toLowerCase().includes(lowercasedTerm)) ||
        (user.status && user.status.toLowerCase().includes(lowercasedTerm)) ||
        (user.phone_number && user.phone_number.toLowerCase().includes(lowercasedTerm))
      );
    }

    // Sorting
    if (sortConfig.key) {
      updatedUsers.sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        // Handle nested metadata fields if needed
        if (sortConfig.key.startsWith('metadata_')) {
          const metadataKey = sortConfig.key.split('_')[1];
          aValue = a.metadata ? a.metadata[metadataKey] : '';
          bValue = b.metadata ? b.metadata[metadataKey] : '';
        }

        if (typeof aValue === 'string') {
          aValue = aValue.toLowerCase();
        }
        if (typeof bValue === 'string') {
          bValue = bValue.toLowerCase();
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredUsers(updatedUsers);
    setCurrentPage(1); // Reset to first page on filter/sort
  }, [searchTerm, users, sortConfig]);

  const handleViewUser = (user) => {
    setSelectedUser(user);
    setIsViewModalOpen(true);
  };

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setEditableData({
      uid: user.uid || '',
      email: user.email || '',
      first_name: user.first_name || '',
      last_name: user.last_name || '',
      status: user.status || '',
      phone_number: user.phone_number || '',
      roles: user.roles || [],
      // Handle metadata as needed
      metadata_signup_source: user.metadata?.signup_source || '',
      metadata_preferences_newsletter: user.metadata?.preferences?.newsletter || false,
      metadata_preferences_notifications: user.metadata?.preferences?.notifications || false,
    });
    setIsEditModalOpen(true);
  };

  const handleDeleteUser = (user) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteUser = async () => {
    if (!selectedUser) return;

    const response = await deleteUser(selectedUser.id);
    if (response.success) {
      const updatedUsers = users.filter(user => user.id !== selectedUser.id);
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      toast.success('User deleted successfully!');
      setIsDeleteModalOpen(false);
    } else {
      console.error(response.error);
      toast.error('Error deleting user.');
    }
  };

  const handleSaveEdit = async (e) => {
    e.preventDefault();
    if (!selectedUser) return;
  
    // Validate roles
    const selectedRoles = editableData.roles;
    if (!selectedRoles || selectedRoles.length === 0) {
      toast.error('Please select at least one role.');
      return;
    }
  
    const validRoleIds = availableRoles.map((role) => role.id);
    const invalidRoles = selectedRoles.filter(
      (role) => !validRoleIds.includes(role)
    );
  
    if (invalidRoles.length > 0) {
      toast.error(`Invalid roles selected: ${invalidRoles.join(', ')}`);
      return;
    }
  
    // Prepare updated data
    const updatedData = {
      uid: editableData.uid,
      email: editableData.email,
      first_name: editableData.first_name,
      last_name: editableData.last_name,
      status: editableData.status,
      phone_number: editableData.phone_number,
      roles: selectedRoles, // Update roles
      metadata: {
        signup_source: editableData.metadata_signup_source,
        preferences: {
          newsletter: editableData.metadata_preferences_newsletter,
          notifications: editableData.metadata_preferences_notifications,
        },
      },
    };
  
    const response = await updateUser(selectedUser.id, updatedData);
    if (response.success) {
      // Update users state
      const updatedUsers = users.map((user) =>
        user.id === selectedUser.id ? { ...user, ...updatedData } : user
      );
      setUsers(updatedUsers);
      setFilteredUsers(updatedUsers);
      toast.success('User updated successfully!');
      setIsEditModalOpen(false);
    } else {
      console.error(response.error);
      toast.error('Error updating user.');
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value, type, checked, options } = e.target;
  
    if (name === 'roles') {
      // Handle multi-select dropdown
      const selectedOptions = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);
      setEditableData((prev) => ({
        ...prev,
        roles: selectedOptions,
      }));
    } else {
      setEditableData((prev) => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };
  
  const handleColumnToggle = (columnKey) => {
    setVisibleColumns(prev => ({
      ...prev,
      [columnKey]: !prev[columnKey],
    }));
  };

  const renderTableHeaders = () => {
    return Object.keys(allColumns).map(columnKey => (
      visibleColumns[columnKey] && (
        <Th key={columnKey} onClick={() => handleSort(columnKey)}>
          <HeaderContent>
            {allColumns[columnKey]}
            {sortConfig.key === columnKey ? (
              sortConfig.direction === 'ascending' ? (
                <SortIcon><FaSortUp /></SortIcon>
              ) : (
                <SortIcon><FaSortDown /></SortIcon>
              )
            ) : (
              <SortIcon><FaSort /></SortIcon>
            )}
          </HeaderContent>
        </Th>
      )
    ));
  };

  const renderTableRow = (user) => {
    return (
      <tr key={user.id}>
        {visibleColumns.uid && <Td>{user.uid}</Td>}
        {visibleColumns.email && <Td>{user.email}</Td>}
        {visibleColumns.first_name && <Td>{user.first_name}</Td>}
        {visibleColumns.last_name && <Td>{user.last_name}</Td>}
        {visibleColumns.status && <Td>{user.status}</Td>}
        {visibleColumns.phone_number && <Td>{user.phone_number}</Td>}
        {visibleColumns.roles && (
          <Td>
            {user.roles && user.roles.length > 0 ? user.roles.join(', ') : 'N/A'}
          </Td>
        )}
{visibleColumns.metadata && (
  <Td>
    {user.metadata ? (
      <details>
        <summary>View Details</summary>
        <p>Signup Source: {user.metadata.signup_source || 'N/A'}</p>
        <p>
          Newsletter: {user.metadata.preferences?.newsletter ? 'Yes' : 'No'}
        </p>
        <p>
          Notifications: {user.metadata.preferences?.notifications ? 'Yes' : 'No'}
        </p>
      </details>
    ) : (
      'N/A'
    )}
  </Td>
)}
        <Td>
          <ActionButton
            variant="view"
            onClick={() => handleViewUser(user)}
            title="View User Details"
          >
            <FaEye /> Read
          </ActionButton>
          <ActionButton
            variant="edit"
            onClick={() => handleEditUser(user)}
            title="Edit User"
          >
            <FaEdit /> Edit
          </ActionButton>
          <ActionButton
            variant="delete"
            onClick={() => handleDeleteUser(user)}
            title="Delete User"
          >
            <FaTrash /> Delete
          </ActionButton>
        </Td>
      </tr>
    );
  };

  // Pagination Logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Sorting Logic
  const handleSort = (columnKey) => {
    let direction = 'ascending';
    if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key: columnKey, direction });
  };

  // Export to Excel
  const handleExport = () => {
    const dataToExport = filteredUsers.map(user => {
      const exportData = {};
      Object.keys(allColumns).forEach(key => {
        if (visibleColumns[key]) {
          if (key === 'metadata') {
            exportData[allColumns[key]] = JSON.stringify(user[key]);
          } else {
            exportData[allColumns[key]] = user[key] || 'N/A';
          }
        }
      });
      return exportData;
    });

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
    XLSX.writeFile(workbook, 'UserData.xlsx');
  };

  // Handle Add User Form Submission
  const handleAddUser = async (e) => {
    e.preventDefault();

    try {
      await addUserToFirestore(newUserUID, newUserEmail);
      toast.success('User added successfully!');
      setIsAddUserModalOpen(false);
      setNewUserUID('');
      setNewUserEmail('');
      // Refresh user list
      const response = await getUsers();
      if (response.success) {
        setUsers(response.users);
        setFilteredUsers(response.users);
      }
    } catch (error) {
      console.error('Error adding user:', error);
      toast.error(error.message || 'Failed to add user.');
    }
  };

  return (
    <>
      <DashboardHeader />
      <Container>
        <Header>
          <Title>User Management</Title>
          <SearchExportContainer>
            <SearchInput
              type="text"
              placeholder="Search by name, email..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ExportButton onClick={handleExport}>
              <FaFileExport /> Export
            </ExportButton>
            {isAdmin && (
              <AddUserButton onClick={() => setIsAddUserModalOpen(true)}>
                <FaUserPlus /> Add User
              </AddUserButton>
            )}
          </SearchExportContainer>
          <ColumnToggleContainer>
            <ColumnToggleButton onClick={() => setIsColumnOptionsOpen(!isColumnOptionsOpen)}>
              <FaCog style={{ marginRight: '5px' }} /> Columns
            </ColumnToggleButton>
            {isColumnOptionsOpen && (
              <ColumnOptions>
                {Object.keys(allColumns).map(columnKey => (
                  <ColumnOption key={columnKey}>
                    <input
                      type="checkbox"
                      id={`toggle-${columnKey}`}
                      checked={visibleColumns[columnKey]}
                      onChange={() => handleColumnToggle(columnKey)}
                    />
                    <label htmlFor={`toggle-${columnKey}`}>{allColumns[columnKey]}</label>
                  </ColumnOption>
                ))}
              </ColumnOptions>
            )}
          </ColumnToggleContainer>
        </Header>
        
        <Table>
          <thead>
            <tr>
              {renderTableHeaders()}
              <Th>Actions</Th>
            </tr>
          </thead>
          <tbody>
            {currentUsers.length > 0 ? (
              currentUsers.map(user => renderTableRow(user))
            ) : (
              <tr>
                <Td colSpan={Object.values(visibleColumns).filter(Boolean).length + 1} style={{ textAlign: 'center' }}>
                  No users found.
                </Td>
              </tr>
            )}
          </tbody>
        </Table>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <PaginationContainer>
            {Array.from({ length: totalPages }, (_, i) => (
              <PageButton
                key={i + 1}
                active={currentPage === i + 1}
                onClick={() => paginate(i + 1)}
              >
                {i + 1}
              </PageButton>
            ))}
          </PaginationContainer>
        )}

        {/* View User Modal */}
        <Modal
          isOpen={isViewModalOpen}
          onRequestClose={() => setIsViewModalOpen(false)}
          contentLabel="View User Details"
          style={{
            content: {
              maxWidth: '600px',
              margin: 'auto',
              inset: '40px',
              borderRadius: '8px',
              padding: '20px',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          <ModalContent>
            <ModalHeader>User Details</ModalHeader>
            {selectedUser && (
              <div>
                <p><strong>UID:</strong> {selectedUser.uid}</p>
                <p><strong>Email:</strong> {selectedUser.email}</p>
                <p><strong>First Name:</strong> {selectedUser.first_name}</p>
                <p><strong>Last Name:</strong> {selectedUser.last_name}</p>
                <p><strong>Status:</strong> {selectedUser.status}</p>
                <p><strong>Phone Number:</strong> {selectedUser.phone_number}</p>
                <p><strong>Roles:</strong> {selectedUser.roles.join(', ')}</p>
                <p><strong>Signup Source:</strong> {selectedUser.metadata?.signup_source || 'N/A'}</p>
                <p><strong>Newsletter:</strong> {selectedUser.metadata?.preferences?.newsletter ? 'Yes' : 'No'}</p>
                <p><strong>Notifications:</strong> {selectedUser.metadata?.preferences?.notifications ? 'Yes' : 'No'}</p>
              </div>
            )}
            <CloseButton onClick={() => setIsViewModalOpen(false)}>Close</CloseButton>
          </ModalContent>
        </Modal>

        {/* Edit User Modal */}
        <Modal
          isOpen={isEditModalOpen}
          onRequestClose={() => setIsEditModalOpen(false)}
          contentLabel="Edit User Details"
          style={{
            content: {
              maxWidth: '600px',
              margin: 'auto',
              inset: '40px',
              borderRadius: '8px',
              padding: '20px',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          <ModalContent>
            <ModalHeader>Edit User Details</ModalHeader>
            {selectedUser && (
              <form onSubmit={handleSaveEdit}>
  {/* UID Field (Uneditable and Not Required) */}
  <div style={{ marginBottom: '10px' }}>
    <label htmlFor="uid" style={{ display: 'block', marginBottom: '5px' }}>UID:</label>
    <input
      type="text"
      id="uid"
      name="uid"
      value={editableData.uid}
      onChange={handleInputChange}
      // Removed 'required' since it's disabled and not editable
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        fontSize: '14px',
      }}
      disabled // UID should generally not be editable
    />
  </div>
  
  {/* Email Field (Uneditable and Not Required) */}
  <div style={{ marginBottom: '10px' }}>
    <label htmlFor="email" style={{ display: 'block', marginBottom: '5px' }}>Email:</label>
    <input
      type="email"
      id="email"
      name="email"
      value={editableData.email}
      onChange={handleInputChange}
      // Removed 'required' since it's disabled and not editable
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        fontSize: '14px',
      }}
      disabled // Email should generally not be editable
    />
  </div>
  
  {/* First Name Field (Optional) */}
  <div style={{ marginBottom: '10px' }}>
    <label htmlFor="first_name" style={{ display: 'block', marginBottom: '5px' }}>First Name:</label>
    <input
      type="text"
      id="first_name"
      name="first_name"
      value={editableData.first_name}
      onChange={handleInputChange}
      // Removed 'required' to make it optional
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        fontSize: '14px',
      }}
    />
  </div>
  
  {/* Last Name Field (Optional) */}
  <div style={{ marginBottom: '10px' }}>
    <label htmlFor="last_name" style={{ display: 'block', marginBottom: '5px' }}>Last Name:</label>
    <input
      type="text"
      id="last_name"
      name="last_name"
      value={editableData.last_name}
      onChange={handleInputChange}
      // Removed 'required' to make it optional
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        fontSize: '14px',
      }}
    />
  </div>
  
  {/* Status Field (Optional) */}
  <div style={{ marginBottom: '10px' }}>
    <label htmlFor="status" style={{ display: 'block', marginBottom: '5px' }}>Status:</label>
    <select
      id="status"
      name="status"
      value={editableData.status}
      onChange={handleInputChange}
      // Removed 'required' to make it optional
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        fontSize: '14px',
      }}
    >
      <option value="">Select Status</option>
      <option value="active">Active</option>
      <option value="inactive">Inactive</option>
      <option value="pending">Pending</option>
      {/* Add more statuses as needed */}
    </select>
  </div>
  
  {/* Phone Number Field (Optional) */}
  <div style={{ marginBottom: '10px' }}>
    <label htmlFor="phone_number" style={{ display: 'block', marginBottom: '5px' }}>Phone Number:</label>
    <input
      type="text"
      id="phone_number"
      name="phone_number"
      value={editableData.phone_number}
      onChange={handleInputChange}
      // Removed 'required' to make it optional
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        fontSize: '14px',
      }}
    />
  </div>
  
  {/* Roles Field (Required) */}
  <div style={{ marginBottom: '10px' }}>
    <label htmlFor="roles" style={{ display: 'block', marginBottom: '5px' }}>
      Roles:
    </label>
    <select
      id="roles"
      name="roles"
      multiple
      value={editableData.roles}
      onChange={handleInputChange}
      required // Retain 'required' since roles are mandatory
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        fontSize: '14px',
        height: '100px',
      }}
    >
      {availableRoles.map((role) => (
        <option key={role.id} value={role.id}>
          {role.name}
        </option>
      ))}
    </select>
    <small>
      Hold down the Ctrl (Windows) or Command (Mac) key to select multiple
      options.
    </small>
  </div>
  
  {/* Metadata Fields (Optional) */}
  <div style={{ marginBottom: '10px' }}>
    <label htmlFor="metadata_signup_source" style={{ display: 'block', marginBottom: '5px' }}>Signup Source:</label>
    <input
      type="text"
      id="metadata_signup_source"
      name="metadata_signup_source"
      value={editableData.metadata_signup_source}
      onChange={handleInputChange}
      // Removed 'required' to make it optional
      style={{
        width: '100%',
        padding: '8px',
        border: '1px solid #cccccc',
        borderRadius: '4px',
        fontSize: '14px',
      }}
    />
  </div>
  
  <div style={{ marginBottom: '10px' }}>
    <CheckboxLabel>
      <input
        type="checkbox"
        name="metadata_preferences_newsletter"
        checked={editableData.metadata_preferences_newsletter}
        onChange={handleInputChange}
      />
      Newsletter Subscription
    </CheckboxLabel>
  </div>
  
  <div style={{ marginBottom: '20px' }}>
    <CheckboxLabel>
      <input
        type="checkbox"
        name="metadata_preferences_notifications"
        checked={editableData.metadata_preferences_notifications}
        onChange={handleInputChange}
      />
      Enable Notifications
    </CheckboxLabel>
  </div>
  
  {/* Save and Cancel Buttons */}
  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
    <SaveButton type="submit">Save</SaveButton>
    <CloseButton type="button" onClick={() => setIsEditModalOpen(false)} style={{ marginLeft: '10px' }}>
      Cancel
    </CloseButton>
  </div>
</form>
            )}
          </ModalContent>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          contentLabel="Confirm Delete"
          style={{
            content: {
              maxWidth: '400px',
              margin: 'auto',
              inset: '40px',
              borderRadius: '8px',
              padding: '20px',
              textAlign: 'center',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          <ModalContent>
            <ModalHeader>Confirm Deletion</ModalHeader>
            {selectedUser && (
              <p>Are you sure you want to delete <strong>{selectedUser.first_name} {selectedUser.last_name}</strong>?</p>
            )}
            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
              <ActionButton variant="delete" onClick={confirmDeleteUser}>
                <FaTrash /> Delete
              </ActionButton>
              <CloseButton onClick={() => setIsDeleteModalOpen(false)} style={{ marginLeft: '10px' }}>
                Cancel
              </CloseButton>
            </div>
          </ModalContent>
        </Modal>
      </Container>

      {/* Add User Modal */}
      <Modal
        isOpen={isAddUserModalOpen}
        onRequestClose={() => setIsAddUserModalOpen(false)}
        contentLabel="Add New User"
        style={{
          content: {
            maxWidth: '500px',
            margin: 'auto',
            inset: '40px',
            borderRadius: '8px',
            padding: '20px',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <ModalContent>
          <ModalHeader>Add New User</ModalHeader>
          <AddUserForm onSubmit={handleAddUser}>
            <label htmlFor="newUserUID">User UID:</label>
            <input
              type="text"
              id="newUserUID"
              name="newUserUID"
              value={newUserUID}
              onChange={(e) => setNewUserUID(e.target.value)}
              required
              placeholder="Enter UID of the user"
            />
            <label htmlFor="newUserEmail">User Email:</label>
            <input
              type="email"
              id="newUserEmail"
              name="newUserEmail"
              value={newUserEmail}
              onChange={(e) => setNewUserEmail(e.target.value)}
              required
              placeholder="Enter email of the user"
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SaveButton type="submit">Add User</SaveButton>
              <CloseButton type="button" onClick={() => setIsAddUserModalOpen(false)} style={{ marginLeft: '10px' }}>
                Cancel
              </CloseButton>
            </div>
          </AddUserForm>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserAccess;
