// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AuthProvider } from './contexts/AuthContext';

// Import ToastContainer and CSS
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Public Pages
import LandingPage from './components/Public/LandingPage';
import PrivacyPolicy from './components/Public/PrivacyPolicy';
import AboutUs from './components/Public/AboutUs';
import Newsletter from './components/Public/Newsletter';

// Private Pages
import NavigationBar from './components/NavigationBar';
import Dashboard from './components/Dashboard';
import Agents from './components/Agents';
import Training from './components/Training';
import Settings from './components/Settings';
import UserAccess from './components/UserAccess';

// Auth Pages
import LoginPage from './components/LoginPage';
import SignupPage from './components/SignupPage';
import ForgotPasswordPage from './components/ForgotPasswordPage';
import NotFoundPage from './components/NotFoundPage';
import Unauthorized from './components/Unauthorized';

// Route Protection Component
import PrivateRoute from './components/PrivateRoute';

const AppContainer = styled.div`
  display: flex;
  height: 100vh; 
  overflow: hidden; 
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

function Layout() {
  const location = useLocation();
  
  // Define paths where the NavigationBar should not be displayed
  const publicPaths = [
    '/',
    '/newsletter',
    '/login',
    '/about-us',
    '/signup',
    '/forgot-password',
    '/privacy-policy',
    '/unauthorized'
  ];

  const showNavigationBar = !publicPaths.includes(location.pathname);

  return (
    <AppContainer>
      {showNavigationBar && <NavigationBar />}
      <ContentContainer>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/newsletter" element={<Newsletter />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          
          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute requiredRoles={['admin', 'team', 'support']}>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/agents"
            element={
              <PrivateRoute requiredRoles={['admin', 'team']}>
                <Agents />
              </PrivateRoute>
            }
          />
          <Route
            path="/training"
            element={
              <PrivateRoute requiredRoles={['admin', 'support']}>
                <Training />
              </PrivateRoute>
            }
          />
          <Route
            path="/user-access"
            element={
              <PrivateRoute requiredRoles={['admin']}>
                <UserAccess />
              </PrivateRoute>
            }
          />
          <Route
            path="/settings"
            element={
              <PrivateRoute requiredRoles={['admin', 'team']}>
                <Settings />
              </PrivateRoute>
            }
          />
          
          {/* Unauthorized and Not Found Routes */}
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ContentContainer>
      {/* Include ToastContainer here */}
      <ToastContainer 
        position="bottom-right"
        autoClose={5000} // Automatically close after 5 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" // You can choose different themes: "light", "dark", "colored"
      />
    </AppContainer>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout />
      </Router>
    </AuthProvider>
  );
}

export default App;
